/* page body */

body {
  text-align: center;
  background: rgb(255, 254, 143);
  background: linear-gradient(
    45deg,
    rgb(255, 225, 143) 0%,
    rgba(255, 211, 140, 1) 100%
  );
}

/* keeps footer at bottom */
.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.mainDiv {
  margin-top: 100px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
}
@media (max-width: 1050px) {
  .mainDiv {
    width: 99%;
  }
}

.writtenAreas {
  width: 650px;
  white-space: normal;
  margin-left: auto;
  margin-right: auto;
  word-wrap: break-word;
  text-align: justify;
}
@media (max-width: 700px) {
  .writtenAreas {
    width: 87%;
  }
}

.tdButtons {
  width: 650px;
  white-space: normal;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 700px) {
  .tdButtons {
    width: 99%;
  }
}

/* tables */

table {
  table-layout: fixed;
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  width: 99%;
  background: white;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  overflow: hidden;
}

td {
  text-align: center;
  background-color: #e6e4e3;
  overflow: hidden;
}

.lighterTable {
  background-color: #f5f2ee;
}

.tableTopRow {
  background-color: #f5f2ee;
}
@media (max-width: 800px) {
  .tableTopRow {
    font-size: 0.5em;
  }
}

.settingsTable {
  table-layout: fixed;
  width: 60%;
}
@media (max-width: 750px) {
  .settingsTable {
    width: 99%;
  }
}

.tableClone {
  table-layout: fixed;
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  width: 99%;
  display: table;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}

.noHighlight {
  background-color: #e6e4e3;
}
@media (max-width: 800px) {
  .noHighlight {
    font-size: 0.6em;
  }
}

.level4Highlight {
  background-color: #f5deb363;
}
@media (max-width: 800px) {
  .level4Highlight {
    font-size: 0.6em;
  }
}

.level3Highlight {
  background-color: #f5deb39d;
}
@media (max-width: 800px) {
  .level3Highlight {
    font-size: 0.6em;
  }
}

.level2Highlight {
  background-color: #f5deb3d3;
}
@media (max-width: 800px) {
  .level2Highlight {
    font-size: 0.6em;
  }
}

.level1Highlight {
  background-color: #f5deb3;
}
@media (max-width: 800px) {
  .level1Highlight {
    font-size: 0.6em;
  }
}

.disengagedEffect {
  background-color: #e7e5e3;
}

.engagedEffect {
  background-color: #e0b3f5;
}

.engagedEffectHeading {
  background-color: #c870f1;
}

.masterVolume {
  table-layout: fixed;
  border: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  display: table;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
}
@media (max-width: 750px) {
  .masterVolume {
    width: 99%;
  }
}

/* links */
/* unvisited link */
a:link {
  color: black;
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

/* visited link */
a:visited {
  color: black;
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

/* mouse over link */
a:hover {
  color: #454adb;
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

/* selected link */
a:active {
  color: #c870f1;
  border-bottom: 1px dotted #000;
  text-decoration: none;
}

/* buttons */

button {
  background-color: #e6e4e3;
  border: 1px inset;
  border-color: black;
  color: black;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* disable highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* most buttons */
.mb:active {
  background-color: #e0b3f5;
}

.fxButtons:active {
  background-color: #9294da;
}

button:active {
  transform: translateY(1px);
  z-index: 1;
}

button:focus {
  outline: none;
}

.infoButton {
  padding: 4px 4px;
}
.infoButton:active {
  background-color: #e0b3f5;
}

.regularPlayButton {
  background-color: #e6e4e3;
}

.activeButton {
  background-color: #e0b3f5;
  transform: translateY(1px);
  z-index: 1;
}

.rootButton {
  background-color: #f5deb3;
}

.activeRootButton {
  background-color: #c870f1;
  transform: translateY(1px);
  z-index: 1;
}

@media (max-width: 1500px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: fit-content;
    padding: 10px 5px;
  }
}
@media (max-width: 1200px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: fit-content;
    padding: 10px 2px;
  }
}
@media (max-width: 1050px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: fit-content;
    padding: 10px 5px;
  }
}
@media (max-width: 950px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 65px;
    overflow: hidden;
    padding: 10px 0px;
  }
}
@media (max-width: 820px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 55px;
  }
}
@media (max-width: 690px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 45px;
  }
}
@media (max-width: 565px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 39px;
  }
}
@media (max-width: 500px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 33px;
  }
}
@media (max-width: 450px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 30px;
  }
}
@media (max-width: 390px) {
  .regularPlayButton,
  .activeButton,
  .rootButton,
  .activeRootButton {
    width: 25px;
  }
}

.tableKeys {
  font-size: large;
  font-style: bold;
}

.chordNotes {
  font-size: smaller;
  font-style: italic;
}

/* oscilloscope */

canvas {
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background: rgba(255, 255, 255, 0.836);
}

/* inputs */

input {
  text-align: center;
}

input[type="number"] {
  background-color: #fff5e3;
}

input {
  background-color: #fff5e3;
}

/* range sliders */

input[type="range"] {
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  background: #b1b1b1;
  height: 0.75rem;
}

input[type="range"]::-moz-range-track {
  background: #b1b1b1;
  height: 0.755rem;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  margin-top: 0px;
  background-color: #6c6ed6;
  height: 0.75rem;
  width: 1rem;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  border-radius: 0;
  background-color: #6c6ed6;
  height: 0.75rem;
  width: 1rem;
}

/* top, bottom, float components */

.bottomCorner {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid black;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 10px 10px;
  border-radius: 10px;
  opacity: 0.8;
  z-index: 1;
}

.bottomBar {
  table-layout: fixed;
  border-top: 1px solid black;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: table;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* border-radius: 10px; */
}

.topBar {
  position: fixed;

  top: 0;
  left: 0;
  /* margin-top: 10px;
  margin-left: 10px; */
  /* opacity: 0.8; */
  width: 100%;
  height: 75px;
  border-bottom: 1px solid black;
  background-color: rgb(238, 238, 238);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  /* padding: 10px 10px; */
  /* border-radius: 10px; */
  z-index: 10;
}

header {
  /* border-bottom: 1px solid #000; */
  display: flex;
  align-items: center;
  width: 100%;
  height: 75px;
  justify-content: flex-end;
}
header > h2 {
  margin-right: auto;
  margin-left: 10px;
}

header span {
  float: right;
  margin-right: 10px;
}

.vl {
  border-left: 3px solid white;
  height: 75px;
  margin-right: 10px;
}

.topButton1 {
  width: 175px;
}
.topButton1:active {
  background-color: #e0b3f5;
}
@media (max-width: 430px) {
  .topButton1 {
    width: 160px;
  }
}
@media (max-width: 410px) {
  .topButton1 {
    width: 130px;
  }
}
.topButton2 {
  width: 215px;
}
.topButton2:active {
  background-color: #e0b3f5;
}

.chordsPlayTable {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  white-space: nowrap;
  touch-action: none;
}

.noSelect {
  /* disable highlight */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.center {
  text-align: center;
}

/* lists */

ul {
  list-style-type: square;
}

ol li {
  padding: 10px;
}

ul li {
  padding-right: 1em;
}

.noPadding {
  padding: none;
}
